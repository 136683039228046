import React, { useEffect, useRef, useState } from "react";
import './footer-styles.css'

export function Footer(){
    const [isVisible, setIsVisible] = useState(false);
    const contact = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          });
        },
        { threshold: 0}
      );
  
      observer.observe(contact.current);
      
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const visible = `${isVisible ? ' show' : ' hidden'}`;

    return(
        <footer className='footer'>
            <p  className={`footer__zennon ${visible}`} ref={contact} >A Zenon Assessoria De Investimentos Ltda. é uma empresa de assessores de investimentos devidamente registrada na Comissão de Valores Mobiliários (na forma da Resolução CVM nº 16/2021” para nova Resolução CVM nº 178/2023). A Zenon Assessoria De Investimentos Ltda atua no mercado financeiro credenciada à XP Investimentos CCTVM S/A, o que pode ser verificado através do site da CVM ou através do site da ANCORD. Na forma da legislação da CVM, o Agente Autônomo de Investimento não pode administrar ou gerir o patrimônio de investidores. O Assessor de Investimentos é um intermediário e depende da autorização prévia do cliente para realizar operações no mercado financeiro. O investimento em ações é um investimento de risco e rentabilidade passada não é garantia de rentabilidade futura. Na realização de operações com derivativos existe a possibilidade de perdas superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais. Para informações e dúvidas, favor contatar seu Assessor de Investimentos. Para reclamações, favor contatar a Ouvidoria da XP Investimentos no telefone nº 0800 722 3730.</p>
            <div className="footer_aditional_infos">
              <div class="footer__logo"/>                
              <p >Site por: <a className="footer__develped_by" rel="noreferrer" href="https://www.linkedin.com/in/david-gama-dev/" target="_blank">David Serrate</a> </p>
              <p className="footer__copy">© Zenon investimentos - 2024 |  Todos direitos reservados.</p>
            </div>
        </footer>
    )
}