import { Tittle } from '../../Components/Common/Tittle';
import { ServiceCard } from '../../Components/ServiceCard'
import React from "react";
import servicesData from '../../data/servicesData'
import './solutions-styles.css'

export function Soluctions(){
    return(
        <section id="solucoes">
            <Tittle tittleTag="Nossas soluções" tittleText="Nossas soluções" />
                <div className='services_container'>                   
                   {servicesData.map((service) => 
                    <ServiceCard  key={service.id}
                        icon={service.icon} 
                        tittle={service.tittle} 
                        description={service.decription}
                    /> )}
                </div>
        </section>
    )
}