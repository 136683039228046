import investimento from '../assets/icones/servico__investimento.png'
import patrimonio from '../assets/icones/servico__planejamento_patrimonio.png'
import sucessorio_familiar from '../assets/icones/servico__planejamento_sucessorio_familiar.png'
import planejamento_risco from '../assets/icones/servico__planejamento_risco.png'
import planejamento_fluxo from '../assets/icones/servico__planejamento_fluxo_caixa.png'

const servicesData= [
    {
        id : 1, 
        icon : investimento,
        tittle : 'INVESTIMENTOS',
        decription : 'Estratégia de acordo com o perfil do cliente com renda fixa, renda variável, fundos, entre outros.'
    } ,
    {
        id : 2, 
        icon : patrimonio,
        tittle : 'PLANEJAMENTO PATRIMONIAL',
        decription : 'Uma estratégia que visa organizar, proteger e perpetuar o patrimônio de uma família ou de uma empresa familiar.'
    } ,
    {
        id : 3, 
        icon : sucessorio_familiar,
        tittle : 'PLANEJAMENTO SUCESSÓRIO',
        decription : 'Forma de organizar a transferência do seu patrimônio para os seus herdeiros, evitando conflitos, burocracias e custos excessivos'
    } ,
    {
        id : 4, 
        icon : planejamento_risco,
        tittle : 'GESTÃO DE RISCOS',
        decription : 'Processo que identifica, analisa e propõe soluções para possíveis ameaças que uma empresa, família ou pessoa está exposta e tentar neutralizar.'
    } ,
    {
        id : 5, 
        icon : planejamento_fluxo,
        tittle : 'FLUXO DE CAIXA',
        decription : 'Entradas e saídas de dinheiro de uma empresa em um determinado período. Ele permite acompanhar as finanças, os investimentos e os rendimentos do negócio.'
    } ,
    
]

export default servicesData