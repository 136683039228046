import React, { useState, useEffect, useRef } from 'react';
import { DividerLine } from '../Common/DividerLine'
import './serviceCard-styles.css'

export function  ServiceCard( { icon, tittle, description }){
    const [isVisible, setIsVisible] = useState(false);
    const serviceRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          });
        },
        { threshold: 0.5}
      );
  
      observer.observe(serviceRef.current);
      
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const visible = `${isVisible ? ' show' : ' hidden'}`;

    return(
      <div className={`service__item ${visible}`} ref={serviceRef}>
        <img src={icon} alt="teste"v className='service__item_icon'/>
        <h2 className='service__item_title'>{tittle}</h2>
        <div className='service_item__divider'>
          <DividerLine />
        </div>
        <p className='service__description'>
          {description}
        </p>
      </div>
    )}