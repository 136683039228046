import React from "react";
import './home-styles.css';
import { Header } from "../../Components/Header";
import BackVideo from '../../assets/bg_zenon.mp4';

export function HomeSection() {
  return (
    <>
      <Header/>
      <section id="home">
        <div className="boxVideo">
          <video className='back_slide_item' autoPlay loop muted playsInline>
            <source className='video_item' src={BackVideo} type='video/mp4'/>
          </video>
          
        </div>
      </section>
    </>
  );
}
