 
import { DividerLine } from "./Components/Common/DividerLine";
import { Footer } from "./Components/Footer";
import { About } from "./Sections/About";
import { Contact } from "./Sections/Contact";
import { HomeSection } from "./Sections/Home";
import { Soluctions } from "./Sections/Solutions";

export function App() {
  return (
    < >
      <HomeSection/>
      <About/>
      <DividerLine />
      <Soluctions/>
      <DividerLine />
      <Contact/>
      <Footer/>
    </>
  );
}
