
const cardsData = [
    {
      id: 1,
      title: "Sobre nós",
      description:
        "Somos um time de profissionais especializados em três áreas: investimentos, advocacia e contabilidade. Temos como missão oferecer um atendimento centralizado e global para cada cliente, com base em nossa experiência e conhecimento. Nossa equipe é formada por pessoas qualificadas, comprometidas e apaixonadas pelo que fazem. Nós trabalhamos com transparência e responsabilidade, buscando sempre a excelência e a satisfação dos nossos clientes. Nós atuamos em diferentes segmentos, atendendo às demandas e aos objetivos de cada cliente, com soluções personalizadas e integradas. Nós somos mais do que um time, somos uma família, que se apoia, se respeita e se orgulha de fazer parte da sua história. Essa é a nossa identidade, a nossa essência e a nossa razão de existir.",
    },
    {
      id: 2,
      title: "Missão",
      description:
        "Nosso atendimento é baseado em uma visão global do cliente, que leva em conta não apenas as suas necessidades atuais, mas também as expectativas futuras das próximas gerações. Queremos ser mais do que prestadores de serviços, queremos ser parceiros na construção da sua história, do seu legado e das suas conquistas, oferecendo apoio e orientação no presente para que possamos alcançar juntos os melhores resultados no futuro. Essa é a nossa forma de valorizar o nosso cliente.",
    },
    {
      id: 3,
      title: "Valor",
      description:
        "Nós valorizamos o nosso cliente como um parceiro de longo prazo, que merece o melhor atendimento e as melhores soluções. Nós nos preocupamos com o seu bem-estar, o seu crescimento e o seu sucesso. Nós buscamos entender as suas necessidades, os seus sonhos e os seus desafios. Nós oferecemos soluções que respeitam o seu presente e o seu futuro, que contribuem para a sustentabilidade das próximas gerações. Esses são os valores que norteiam o nosso atendimento e que nos motivam a fazer sempre mais e melhor por você.",
    },
  ];


export default cardsData