const menuItems =[
    {
        id: 1, 
        tittle : "home",
        label : "Home",
        offset : 0
    },
    {
        id: 2, 
        tittle : "sobre" ,
        label : "Sobre nós" ,
        offset :  -55,
    },
    {
        id: 3, 
        tittle : "solucoes",
        label :  "Nossas soluções",
        offset : -100
    },
    {
        id: 4, 
        tittle : "contato",
        label : "Contato",
        offset :  -125
    },
]
 

export default menuItems