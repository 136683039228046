import { AboutCard } from '../../Components/AboutCard';
import { DividerLine } from '../../Components/Common/DividerLine';
import cardsData from '../../data/cardsData';

import './about-styles.css'
 
export function About(){
    return(
        <section id="sobre">
            {cardsData.map((card)=> 
                <>
                <AboutCard 
                    key={card.id}
                    cardKey={card.id}
                    title={card.title}
                    description={card.description}
                />
                {card.id< 3 &&
                <div className='about_divider'>
                    <DividerLine />
                </div>}
                </>
            )}
            
        </section>
    )
}