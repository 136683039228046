import React, { useState, useEffect, useRef } from 'react';
import './about-styles.css';
import { Tittle } from '../Common/Tittle';

export function AboutCard({  title, description, cardKey }) {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.5}
    );

    observer.observe(cardRef.current);
    
    return () => {
      observer.disconnect();
    };
  }, []);

  const cardClasses = `about_item ${isVisible ? 'show' : 'hidden'}`;

  return (
    <div className={cardClasses} ref={cardRef} key={cardKey}>
      <Tittle tittleTag={title} tittleText={title} type="unique"/>
        <div className={`about_item ${isVisible}`}>
          <p className='sobre_description'>
            {description}
          </p>
      </div>
    </div>
  );
}
