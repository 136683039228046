import emailjs from 'emailjs-com'

const TEMPLATE_ID = 'template_vvf6wt9'
const SERVICE_ID= 'service_gtqgimi'
const PUBLIC_KEY = '0EueV94GHCEj0silF'

export async function SendEmail (templateParams){
    
    const avaliableToSend = templateParams.nome!==''  && templateParams.email!=='' && templateParams.telefone !=='' && templateParams.mensagem!==''
    console.log(avaliableToSend)
  
    if(avaliableToSend){
        await emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams,PUBLIC_KEY ).then(
                function(response){
                    console.log('sucesso', response.status, response.text)
                }, 
                function(error){
                    console.log('falha', error)
                })

    }
}