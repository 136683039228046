 
import {Tittle} from '../../Components/Common/Tittle'
import './contact-styles.css'
import { SendEmail } from '../../services/sendMail';
import {Input} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Alert, Button, Form } from 'antd';
import { MailFilled, MailOutlined, PhoneFilled } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';
import { SocialIcon } from 'react-social-icons';
import socialNetworks from '../../data/socialNetwork';

export function Contact(){
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [isError,setIsError] = useState();
    const [text, setText] = useState('');
    
    let templateParams ={
      nome :nome ,
      email:email,
      telefone:telefone,
      mensagem:mensagem
    }

    const sendMail  = ()=>{
        if(nome === '' || telefone === '' || email === ''  || mensagem === ''){
          setIsError(true)
          setText('Por favor, preencha todos os campos')
        }
        else{
          setText('Email enviado com sucesso!') 
          setIsError(false)
          SendEmail(templateParams)   
          setNome('')
          setTelefone('')
          setEmail('')
          setMensagem('')

        }
        setTimeout(() => {
          setIsError(undefined)
          setText('') 
        }, 2 * 1000);
    }

    const [isVisible, setIsVisible] = useState(false);
    const contactRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          });
        },
        { threshold: 0}
      );
  
      observer.observe(contactRef.current);
      
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const cardClasses = `${isVisible ? 'show' : 'hidden'}`;

    return(
        <>
          <div className='contato_section'>
            <Tittle tittleTag="Converse Conosco" tittleText="Converse Conosco" type="unique" color="#fff"/>
            <div className='contato_ext'>
                <section id="contato" className={`${cardClasses}`} ref={contactRef}>
                  <div  className='box_form'>
                    <Form className='send-mail_form' >
                        <div className='send-mail_form__tittle_div'>                          
                          <h2  className='mail_form__tittle' >Envie sua Mensagem</h2>
                        </div>
                        <Input className='input_item' variant="outlined" value={nome}  placeholder="Nome"  onChange={ (e) => {setNome(e.target.value)}}/>
                        <Input className='input_item' variant="outlined" value={telefone}   placeholder="Telefone"  onChange={ (e) => {setTelefone(e.target.value)}}/>
                        <Input  className='input_item' value={email} id="outlined-basic" placeholder="E-mail" variant="outlined"  onChange={ (e) => {setEmail(e.target.value)}}/> 
                        <TextArea className='input_item' style={{minHeight:'150px'}} value={mensagem} placeholder="Mensagem"  onChange={ (e) => {setMensagem(e.target.value)}} />
                        {isError !== undefined && 
                            <Alert message={text} type={isError ? "error" : "success"} />}
                        <Button type="primary"  style={{width:'100%', backgroundColor:'var(--tittle-primary)', fontWeight:'bold'}}  onClick={sendMail} size='large' icon={<MailOutlined />}>Enviar</Button>
                    </Form>
                  </div>
                </section>
                <section className='contato_mail_phone_social'>
                  <div className='contato_mail_phone'>
                    <div className='contact_phone'>
                      <div><PhoneFilled  rotate={90}/></div>
                      <div> 44 3218 2300</div>
                    </div>
                    <div className='contact_mail'>
                      <div><MailFilled /></div>
                      <div>contato@zenoncapital.com.br</div>
                    </div> 
                  </div>  
                  <div className='contato_social'>
                    {socialNetworks.map((social)=><SocialIcon key={social.id} className='contato_social_item'url={social.link} bgColor='var(--background)'/>)}
                  </div>         
                </section>
            </div>
        </div>
        </>
    )
}

 